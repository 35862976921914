import React from 'react'

const NotFoundPage = () => (
  <div>
    <h1>Oops! 🙊</h1>
    <p>This route doesn't exist, but while you're here you should check out some of my favorite books!</p>
    <ul>
      <li>&rarr; The Shadow of the Wind</li>
      <li>&rarr; Extremely Loud & Incredibly Close</li>
      <li>&rarr; 100 Years of Solitude</li>
      <li>&rarr; The Angel's Game</li>
      <li>&rarr; Brave New World</li>
      <li>&rarr; Life of Pi</li>
      <li>&rarr; Wuthering Heights</li>
      <li>&rarr; My Cat Yugoslavia</li>
    </ul>
  </div>
)

export default NotFoundPage
